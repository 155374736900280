import _last from 'lodash/last';
import { fromJS, Map, List, get, remove, isImmutable } from 'immutable';
import format from 'date-fns/format';
import { LOCATION_CHANGE } from 'react-router-redux';
import { getDateUTC } from '@dbh/dates';
import { REACT_ROUTER_MATCH } from '@dbh/redux-route-internals';
import { SSR_STARTED } from '@dbh/ssr-data-redux';
import PropTypes from 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { COOKIE_KEYS } from '@dbh/cookie-keys';
import { getImmutableCookie } from '@dbh/cookies';
import { createSelector } from 'reselect';
import { keyIn } from '@dbh/lodash-extra';
import { createSimpleSelector, createSimpleBooleanSelector } from '@dbh/reselect-extra';

const NOT_AVAILABLE="N/A",getResponseDataFromLoggerEvent=a=>{const b=a.get("pathname"),c=a.get("pageType"),d=a.get("type"),e=a.get("time"),f=a.get("requestAttributes"),g=a.get("idx"),h={pageType:c,pathname:b,type:_last(d.split("/")),time:e,isSSR:!1,idx:g};if(0===g&&(h.isSSR=!0),f){const{isServerSideRendering:a,response:{beIsCachedResult:d,beAuthenticatedResult:e,beUpstreamTime:g,beDateCachedResult:i,status:j,networkTime:k,computeTime:l,totalTime:m,contentEncoding:n,cfToBeNetworkTime:o,cfToBeComputeTime:p,cfToBeTotalTime:q,cfWorkerUpstreamTime:r,cfIsCachedResult:s,isProxiedByCf:t,cfWasRequestAuthenticated:u},request:{url:v,method:w,options:{body:y},authenticated:x},contentLengthKB:b,dryRun:c}=f.toJS(),z="POST"===w||"PUT"===w||"PATCH"===w?JSON.stringify(JSON.parse(y),"\n",2):void 0,A=l?l.ms:void 0,B=s?{cfToBeNetworkTimeCached:o,cfToBeComputeTimeCached:p,cfToBeTotalTimeCached:q,beUpstreamTimeCached:g,cfWasRequestAuthenticatedCached:u}:{cfToBeNetworkTime:o||NOT_AVAILABLE,cfToBeComputeTime:p||NOT_AVAILABLE,cfToBeTotalTime:q||NOT_AVAILABLE,beUpstreamTime:g||NOT_AVAILABLE,cfWasRequestAuthenticated:u};// We don't receive `computeTime` in response with `dryRun: true`.
// @TODO: test `n/a`, prop type error somewhere?
// The values of the `Cloudflare` cached timings are the same, so we use
// different names to place them under corresponding columns to avoid confusion.
// @see `@dbh/development-redux#loggerEventsColumns`.
return fromJS({...h,isSSR:a?"true":"false",responseStatus:j,networkResponseTime:k.ms,computingResponseTime:A,totalResponseTime:m.ms,cfIsCachedResult:s,isProxiedByCf:t,...B,cfWorkerUpstreamTime:r||NOT_AVAILABLE,requestUrl:decodeURIComponent(v),method:w,requestBody:z,contentLengthKB:b,requestAuthenticated:x,responseIsCachedResult:d,responseIsAuthenticatedResult:e,responseDateCachedResult:i,responseContentEncoding:n,dryRun:c})}return fromJS(h)};/**
 * Gets response data from a logger event.
 * @param {Immutable.Map} loggerEvent .
 * @return {Immutable.Map} .
 */var getResponseDataFromLoggerEvent$1 = getResponseDataFromLoggerEvent;

const REDUX_AND_SAGA_KEY="logger";const ERROR_LOGGED="@dbh/logger-redux/ERROR_LOGGED";const WARNING_LOGGED="@dbh/logger-redux/WARNING_LOGGED";const LOGGER_ENABLED_IN_SSR="@dbh/logger-redux/LOGGER_ENABLED_IN_SSR";

const initialState=Map({printErrorsInConsole:!0,printWarningsInConsole:!0,locationState:Map(),events:List(),// We enable the logger everywhere excluding `www.daybreakhotels.com`
// and `test-production-whatever.daybreakhotels.com`, because in those
// cases we want the maximum performance and don't want to risk any slowdown.
loggerEnabledInSSR:!1}),payloadWithDate=a=>fromJS({...a,date:getDateUTC()}),currentHoursMinutes=()=>format(new Date,"HH:mm"),pushEventToState=(a,b)=>{let{type:c,payload:d}=b;const e=get(d,"requestAttributes"),f=get(d,"pathname"),g=a.getIn(["locationState","pathname"]),h=a.getIn(["locationState","pageType"]);// Update the events list if the payload contains the request attributes,
// or if the type is `LOCATION_CHANGE`.
if(d&&e||c===LOCATION_CHANGE){const b={pathname:f||g,pageType:h,time:currentHoursMinutes(),type:c,// Separating payload and `requestAttributes` makes more sense here.
payload:remove(d,"requestAttributes"),requestAttributes:e,idx:a.get("events").size};return a.update("events",a=>isImmutable(a)?a.push(fromJS(b)):fromJS([b]))}return a},loggerReducer=function(a,b){void 0===a&&(a=initialState);const{type:c,payload:d}=b,e=d&&d.pathname,f=d&&d.PAGE_TYPE,g=a.getIn(["locationState","pathname"]),h=e||g;switch(c){case ERROR_LOGGED:return a.updateIn([h,"errors"],a=>(a||List()).push(payloadWithDate(d)));case WARNING_LOGGED:return a.updateIn([h,"warnings"],a=>(a||List()).push(payloadWithDate(d)));case REACT_ROUTER_MATCH:return a.mergeIn(["locationState"],Map({pageType:f}));case SSR_STARTED:{const{url:c}=b,d=c.split("?"),e=d[0],f="?"+d[1];return a.mergeIn(["locationState"],Map({pathname:e,search:f}))}case LOCATION_CHANGE:{const{search:c}=d,f=a.mergeIn(["locationState"],Map({pathname:e,search:c}));// In case of a `LOCATION_CHANGE` we want to update the
// location state AND the events list.
return pushEventToState(f,b)}case LOGGER_ENABLED_IN_SSR:return a.set("loggerEnabledInSSR",!0);// Catch all the other events and update the event list.
default:return pushEventToState(a,b)}};var loggerReducer$1 = loggerReducer;

const{USE_LOGGER_IN_WWW}=COOKIE_KEYS;const getImmutableCookieUseLoggerInWWW=a=>{const b=getImmutableCookie(a,USE_LOGGER_IN_WWW);return null==b?void 0:b.get("value")};

/**
 * Returns `true` if the `redux` logger module is enabled. We disable it in
 * `www.daybreakhotels.com` not to impact performance, unless a specific cookie
 * is set with the development bar.
 * @param {Object} universalCookies .
 * @return {boolean} .
 */const isLoggerReduxEnabled=withConformsTo("isLoggerReduxEnabled",["universalCookies",PropTypes.object.isRequired],a=>getImmutableCookieUseLoggerInWWW(a));var isLoggerReduxEnabled$1 = isLoggerReduxEnabled;

const selectLogger=a=>a.get(REDUX_AND_SAGA_KEY);var selectLogger$1 = selectLogger;

/**
 * Returns a selector that returns `true` is the logger reducer has been injected
 * correctly.
 * @return {boolean} .
 */const makeSelectIsLoggerEnabled=()=>createSelector(selectLogger$1,Map.isMap);var makeSelectIsLoggerEnabled$1 = makeSelectIsLoggerEnabled;

/**
 * Returns a selector that returns the configuration of the logger.
 * @return {Immutable.Map?} .
 */const makeSelectLoggerConfiguration=()=>createSelector(selectLogger$1,a=>a?a.filter(keyIn("printErrorsInConsole","printWarningsInConsole")):void 0);var makeSelectLoggerConfiguration$1 = makeSelectLoggerConfiguration;

/**
 * Returns a selector that returns the events recorded by the logger.
 * @return {Immutable.List?} .
 */const makeSelectEvents=()=>createSimpleSelector(selectLogger$1,["events"]);var makeSelectEvents$1 = makeSelectEvents;

/*
 *
 * `generatePostmanCollectionFile`: `@dbh/logger-redux`.
 *
 */const createJsonFile=a=>new Blob([a],{type:"text/plain"}),generatePostmanCollectionFile=async a=>{const b=a.get("events").toJS(),{Collection:c,RequestBody:d}=await import('postman-collection'/* webpackChunkName: "postman-collection" */),e=b.reduce((a,b)=>{let{requestAttributes:c}=b;if(c){const{request:{url:b,method:e,options:{body:f,dbhHeaders:g}}}=c,h=f?new d({mode:"raw",raw:JSON.stringify(JSON.parse(f),null,2)}):void 0,i=g,j=i&&Object.keys(i).map(a=>({key:a,value:i[a]}));a.items.add({name:b,request:{url:b,method:e,body:h,header:j||[{key:"Content-Type",value:"application/json"}]}});}return a},new c),f=JSON.stringify(e.toJSON());return createJsonFile(f)};var generatePostmanCollectionFile$1 = generatePostmanCollectionFile;

/**
 * Returns a selector that returns the `Postman` collection data.
 * @return {Blob} .
 */const makeSelectPostmanCollection=()=>createSelector(selectLogger$1,generatePostmanCollectionFile$1);var makeSelectPostmanCollection$1 = makeSelectPostmanCollection;

/**
 * Returns a selector that returns `true` if the the logger was enabled in `SSR`.
 * In `www`, for performance reasons, we don't enable it in `SSR` unless a development
 * only, specific cookie is set.
 * @return {boolean} .
 */const makeSelectIsLoggerEnabledInSSR=()=>createSimpleBooleanSelector(selectLogger$1,["loggerEnabledInSSR"]);var makeSelectIsLoggerEnabledInSSR$1 = makeSelectIsLoggerEnabledInSSR;

/**
 * Log the error.
 * @param {Object} options .
 * @param {Error} options.error .
 * @param {string} options.pathname .
 * @param {string} options.message .
 * @return {Object} .
 */const errorLogged=a=>{let{error:b,pathname:c,message:d}=a;return {type:ERROR_LOGGED,payload:{error:b,pathname:c,message:d}}};/**
 * Log the warning.
 * @param {Object} options .
 * @param {string} options.warning .
 * @param {string} options.pathname .
 * @param {string} options.message .
 * @return {Object} .
 */const warningLogged=a=>{let{warning:b,pathname:c,message:d}=a;return {type:WARNING_LOGGED,payload:{warning:b,pathname:c,message:d}}};const loggerEnabledInSsr=()=>({type:LOGGER_ENABLED_IN_SSR});

/*
 *
 * Package: `@dbh/logger-redux`.
 *
 */const importLoggerSagas=import('./index-0bfad35a.js'/* webpackChunkName: 'dbh.logger-redux.sagas' */);

export { ERROR_LOGGED, LOGGER_ENABLED_IN_SSR, REDUX_AND_SAGA_KEY, WARNING_LOGGED, errorLogged, getImmutableCookieUseLoggerInWWW, getResponseDataFromLoggerEvent$1 as getResponseDataFromLoggerEvent, importLoggerSagas, isLoggerReduxEnabled$1 as isLoggerReduxEnabled, loggerEnabledInSsr, loggerReducer$1 as loggerReducer, makeSelectEvents$1 as makeSelectEvents, makeSelectIsLoggerEnabled$1 as makeSelectIsLoggerEnabled, makeSelectIsLoggerEnabledInSSR$1 as makeSelectIsLoggerEnabledInSSR, makeSelectLoggerConfiguration$1 as makeSelectLoggerConfiguration, makeSelectPostmanCollection$1 as makeSelectPostmanCollection, selectLogger$1 as selectLogger, warningLogged };
