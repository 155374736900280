import PropTypes from 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

const getLocales=withConformsTo("getLocales",["options",PropTypes.exact({appLocalesCsv:PropTypes.string.isRequired,appLocalesIso6391Csv:PropTypes.string.isRequired,appCountriesCsv:PropTypes.string.isRequired}).isRequired],a=>{let{appLocalesCsv:b,appLocalesIso6391Csv:c,appCountriesCsv:d}=a;// All locales supported by this app. @example `it-IT`, `en-GB`, `fr-CH`.
// They are defined in `package.json#supportedAppLocales` and defined as environment
// variables thanks to `webpack.DefinePlugin`, if the code is compiled by `webpack`.
// Instead when we are in `test` and `scripts` environments, we use
// `babel-plugin-transform-define` to define them as environment variables.
// Where are these created? @see `@dbh/environment-variables`.
const e=b.replace(/['"]+/g,"").split(","),f=c.replace(/['"]+/g,"").split(","),g=d.replace(/['"]+/g,"").split(",");return [e,f,g]});var getLocales$1 = getLocales;

const [appLocales,appLocalesIso6391,appCountries]=getLocales$1({appLocalesCsv:"ar-AE,de-AT,de-CH,de-DE,en-AU,en-GB,en-US,es-AR,es-CL,es-ES,fr-BE,fr-CH,fr-FR,it-CH,it-IT,pt-BR,ru-RU,en-CA,fr-CA",appLocalesIso6391Csv:"ar,de,en,es,fr,it,pt,ru",appCountriesCsv:"DE,BE,RU,AE,CH,CL,IT,FR,ES,BR,AR,AT,AU,GB,CA,US"}),DEFAULT_LOCALE="it-IT",DEFAULT_COUNTRY="IT";

export { DEFAULT_COUNTRY, DEFAULT_LOCALE, appCountries, appLocales, appLocalesIso6391 };
