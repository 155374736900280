import 'invariant';
import { css } from 'styled-components';
import { breakpoints } from '@dbh/breakpoints';
import 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

/**
 * Creates and returns a function that calls the add or remove event listener
 * function, on the given media query list.
 * @param {boolean} createAddFunction .
 * @return {Function} .
 */const createAddRemoveEventListener=withConformsTo("createAddRemoveEventListener",[],a=>withConformsTo(a?"addEventListenerToMediaQueryList":"removeEventListenerFromMediaQueryList",[],(b,c)=>{const d=a?"addListener":"removeListener",e=a?"addEventListener":"removeEventListener";"function"==typeof b[e]?b.addEventListener("change",c):b[d](a=>{a.matches&&c();});}));var createAddRemoveEventListener$1 = createAddRemoveEventListener;

const addEventListenerToMediaQueryList=createAddRemoveEventListener$1(!0);var addEventListenerToMediaQueryList$1 = addEventListenerToMediaQueryList;

const removeEventListenerFromMediaQueryList=createAddRemoveEventListener$1(!1);var removeEventListenerFromMediaQueryList$1 = removeEventListenerFromMediaQueryList;

// 'xs' is from 0 to 'sm', we don't use a media query to target it: 'mobile-first'.
const media=Object.keys(breakpoints).reduce((a,b)=>{const c="(min-width: "+breakpoints[b]+"px)";return a[b]=function(){return css(["@media ","{",";}"],c,css(...arguments))},a},{});const matchMediaDesktop="(min-width: "+breakpoints.lg+"px)";

export { addEventListenerToMediaQueryList$1 as addEventListenerToMediaQueryList, media as default, matchMediaDesktop, removeEventListenerFromMediaQueryList$1 as removeEventListenerFromMediaQueryList };
