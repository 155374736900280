import { createSelector } from 'reselect';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { ADMINDEV_WEBCONTROLS_REFERENCES } from '@dbh/admindev-constants';
import { makeSelectLastestWebcontrolLoadedByReference } from '@dbh/webcontrols-redux';
import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import 'invariant';
import 'prop-types';
import { ThemeContext } from 'styled-components';
import { FE_API_IDS } from '@dbh/api-constants';
import { makeSelectFrontEndApiUrl } from '@dbh/configuration-redux';
import { appendQueryParams } from '@dbh/urls';
import _noop from 'lodash/noop';
import '@dbh/theme-values';

/**
 * Returns a selector that returns the list of icons in the `images` navigation
 * menu in the `admindev`; that navigation menu is expected to be associated to
 * all pages in the site, in the `admindev`.
 * @return {(Immutable.List<Immutable.Map>)?} .
 */const makeSelectIcons=()=>createSelector(makeSelectLastestWebcontrolLoadedByReference(ADMINDEV_WEBCONTROLS_REFERENCES.IMAGES),a=>a?a.get("elements").find(a=>"icons"===a.get("reference")).get("elements"):void 0);var makeSelectIcons$1 = makeSelectIcons;

/*
 *
 * Constants: `@dbh/images-redux`.
 *
 */// The values are the `reference` fields of the images in the `images`
// navigation menu (`webcontrol`), in the `admindev`.
// @see {@link https://apidev.daybreakhotels.com/v1.2/IT/en-GB/webcontrols/images}
const ADMIN_IMAGES={ARROW_RIGHT:"arrow-right",ARROW_LEFT:"arrow-left",ARROW_DOWN:"arrow-down",ARROW_UP:"arrow-up",USER_ICON:"user-icon",CLOSE:"close",CALENDAR:"calendar",EXCLAMATION_CAUTION_SIGN:"exclamation-caution-sign",EXPAND_MORE:"expand-more",PIN_FULL_MAP:"pin-full-map",PIN_FULL:"pin-full",BELL:"bell",STAR:"star",LOCATION_PIN:"location-pin",SMILEY_RATING_1:"smiley-rating-1",SMILEY_RATING_2:"smiley-rating-2",SMILEY_RATING_3:"smiley-rating-3",SMILEY_RATING_4:"smiley-rating-4",SMILEY_RATING_5:"smiley-rating-5",HOTEL_PIN:"hotel-pin",SORT:"sort",DOWN_SYMBOL:"down-symbol",SUN:"sun",SUNSET:"sunset",HALF_MOON:"half-moon",CHECK_IN_TIME:"check-in-time",LIGHT_BULB:"light-bulb",LIST_DISC:"list-disc",TRASH:"trash",CREDIT_CARD:"credit-card",EXCLAMATION_MARK:"exclamation-mark",BOLD_TICK:"bold-tick",LUGGAGE:"luggage",FILTER:"filter",MENU:"menu",PHONE:"phone",SEARCH:"search",GEO:"geo",GEOLOCATION_PIN:"geolocation-pin",SPA:"spa",APARTMENT:"apartment",ROOM:"room",RESTAURANT:"restaurant",MEETING_ROOM:"meeting-room",LIST:"list",ADD_INDICATOR:"add-indicator",CLEAR:"clear",ROSE:"rose",COCKTAIL:"cocktail",CAR:"car",TICK:"tick",FACEBOOK_LOGO:"facebook-logo",GOOGLE_LOGO:"google-logo",MAIL_BOX:"mail-box",CARTAFRECCIA:"cartafreccia",HOTEL:"hotel",BELL_RESERVATION:"bell-reservation",GIFT_BOX:"gift-box",CAUTION_SIGN:"caution-sign",CLOCK:"clock",FOLDER:"folder",MAIL:"mail",MAP_MARKER:"map-marker"};

/**
 * @typedef {Object} AdminImageObject .
 * @property {string} reference .
 * @property {string} firstColor .
 * @property {string?} secondColor .
 *//**
 * @typedef {string|AdminImageObject} AdminImage .
 */const adminImagePropType=_noop;/**
 * @typedef {Object} IconWithColorOptions .
 * @property {string} iconUrl .
 * @property {string} firstColor .
 * @property {string} secondColor .
 */const iconWithColorOptionsPropType=_noop;const iconWithColorOptionsPropTypeList=_noop;

/**
 * Returns a selector that returns the CDN `URL` of the icon with the given
 * reference, if one exists in the `images` navigation menu in the `admindev`.
 * @param {string} iconName .
 * @return {string?} .
 */const makeSelectIconByReference=withConformsTo("makeSelectIconByReference",[],a=>createSelector(makeSelectIcons$1(),b=>{if(b){const c=b.find(b=>b.get("reference")===a);return c?c.get("secondaryImageUrl"):void 0}}));var makeSelectIconByReference$1 = makeSelectIconByReference;

/**
 * @typedef {import('./types').IconWithColorOptions} IconWithColorOptions
 *//**
 * Returns adapted icon `URLs` with colors in the query string.
 * @param {IconWithColorOptions|IconWithColorOptions[]} adminIcons .
 * @return {string} .
 */const useIconsWithColors=withConformsTo("useIconsWithColors",[],a=>{const{colors:b}=useContext(ThemeContext),c=useSelector(useMemo(()=>makeSelectFrontEndApiUrl(FE_API_IDS.beCdnImagesAdapter),[])),[d,e,f]=c;let g=d;!f&&["test","www"].includes(e)&&(g=void 0);const h=Array.isArray(a),i=h?a:[a],j=g?new URL(g):void 0,k=i.map(a=>{if(!a)return;const{iconUrl:c,firstColor:d,secondColor:e}=a;if(!c)// This is a hook, it must be always called, even when the data isn't
// already loaded.
return;let f;if(j&&(f=new URL(c),f.hostname=j.hostname,f.pathname=""+j.pathname+f.pathname),!d&&!e)return f?f.toString():c;// We don't accept any color; only if it's in the theme (so, either one
const i=appendQueryParams(f||c,{firstColor:b[d],secondColor:b[e]});return i});if(h)return k;const[l]=k;return l});var useIconsWithColors$1 = useIconsWithColors;

const useIconByReference=withConformsTo("useIconByReference",[],a=>{const b="string"==typeof a?a:a.reference,c=useSelector(useMemo(()=>makeSelectIconByReference$1(b),[b])),d=useIconsWithColors$1({iconUrl:c,firstColor:a.firstColor,secondColor:a.secondColor});return d});var useIconByReference$1 = useIconByReference;

const DEFAULT_RETURN_VALUE$1=[],makeSelectIconsByReference=withConformsTo("makeSelectIconsByReference",[],a=>createSelector(makeSelectIcons$1(),b=>{if(b){const c=a.map(a=>{const c="string"==typeof a?a:a.reference,d=b.find(a=>a.get("reference")===c);return d?{iconUrl:d.get("secondaryImageUrl"),firstColor:a.firstColor,secondColor:a.secondColor}:void 0});return c||DEFAULT_RETURN_VALUE$1}}));/**
 * Returns a selector that returns the CDN `URLs` of the icons with the given
 * references, if one exists in the `images` navigation menu in the `admindev`.
 * @param {string[]} adminIconReferences .
 * @return {(Object[])?} Empty if no matching images were found, `undefined` if
 * the required `webcontrols` "Navigation Menu" is not associated to the current
 * page or still not loaded.
 */var makeSelectIconsByReference$1 = makeSelectIconsByReference;

const useIconsByReference=withConformsTo("useIconsByReference",[],a=>{const b=useSelector(useMemo(()=>makeSelectIconsByReference$1(a),// eslint-disable-next-line react-hooks/exhaustive-deps
a)),c=useIconsWithColors$1(b||[]);return c});var useIconsByReference$1 = useIconsByReference;

/**
 * Returns a selector that returns the CDN `URL` and the dimensions of the icon
 * with the given reference, if one exists in the `images` navigation menu in
 * the `admindev`.
 * @param {string} iconName .
 * @return {string?} .
 */const makeSelectIconByReferenceWithDimensions=withConformsTo("makeSelectIconByReference",[],a=>createSelector(makeSelectIcons$1(),b=>{if(b){const c=b.find(b=>b.get("reference")===a);return c?{url:c.get("secondaryImageUrl"),width:c.get("secondaryImageWidth"),height:c.get("secondaryImageHeight")}:void 0}}));var makeSelectIconByReferenceWithDimensions$1 = makeSelectIconByReferenceWithDimensions;

const useIconByReferenceWithDimensions=withConformsTo("useIconByReference",[],a=>{const b="string"==typeof a?a:a.reference,c=useSelector(useMemo(()=>makeSelectIconByReferenceWithDimensions$1(b),[b]));if(!c)// This should never happen, excluding in pages that are redirected, and
// for which the `/webcontrols` `API` returns an empty result (empty
// `hydra:member`). In that case, it's important this selector doesn't
// crash, or the site will red screen, and the redirect won't work.
return;const{url:d}=c,e=useIconsWithColors$1({iconUrl:d,firstColor:a.firstColor,secondColor:a.secondColor});return {...c,url:e}});var useIconByReferenceWithDimensions$1 = useIconByReferenceWithDimensions;

const DEFAULT_RETURN_VALUE=[],makeSelectIconsByReferenceWithDimensions=withConformsTo("makeSelectIconsByReference",[],a=>createSelector(makeSelectIcons$1(),b=>{if(b){const c=a.map(a=>{const c=b.find(b=>b.get("reference")===a);return c?{url:c.get("secondaryImageUrl"),width:c.get("secondaryImageWidth"),height:c.get("secondaryImageHeight")}:void 0});return c||DEFAULT_RETURN_VALUE}}));/**
 * Returns a selector that returns the cdn `URLs` and the dimensions of the
 * icons with the given references, if one exists in the `images` navigation
 * menu in the `admindev`.
 * @param {string[]} adminIconReferences .
 * @return {(string[])?} Empty if no matching images were found, `undefined` if
 * the required `webcontrols` "Navigation Menu" is not associated to the current
 * page or still not loaded.
 */var makeSelectIconsByReferenceWithDimensions$1 = makeSelectIconsByReferenceWithDimensions;

export { ADMIN_IMAGES, adminImagePropType, iconWithColorOptionsPropType, iconWithColorOptionsPropTypeList, makeSelectIconByReference$1 as makeSelectIconByReference, makeSelectIconByReferenceWithDimensions$1 as makeSelectIconByReferenceWithDimensions, makeSelectIconsByReference$1 as makeSelectIconsByReference, makeSelectIconsByReferenceWithDimensions$1 as makeSelectIconsByReferenceWithDimensions, useIconByReference$1 as useIconByReference, useIconByReferenceWithDimensions$1 as useIconByReferenceWithDimensions, useIconsByReference$1 as useIconsByReference };
